import { useCompanyInformation, useGetLinkLocaleSlug, useNewsroom } from '@prezly/theme-kit-nextjs';
import Image from '@prezly/uploadcare-image';
import classNames from 'classnames';
import Link from 'next/link';

import { LOGO_IMAGE } from '@/constants';
import { useDevice } from '@/hooks/useDevice';

import styles from './TopBanner.module.scss';

export default function TopBanner() {
    const { display_name } = useNewsroom();
    const { name, about_plaintext } = useCompanyInformation();
    const getLinkLocaleSlug = useGetLinkLocaleSlug();
    const { isMobile } = useDevice();

    const newsroomName = name || display_name;

    if (isMobile) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={classNames('container', styles.inner)}>
                <Link href="/" locale={getLinkLocaleSlug()}>
                    <Image
                        imageDetails={LOGO_IMAGE}
                        className={styles.logo}
                        alt={newsroomName}
                        layout="fill"
                        objectFit="contain"
                    />
                </Link>
                <p className={styles.introduction}>{about_plaintext}</p>
            </div>
        </div>
    );
}
