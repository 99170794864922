import { getCategoryHasTranslation } from '@prezly/theme-kit-core';
import { translations } from '@prezly/theme-kit-intl';
import { useCurrentLocale } from '@prezly/theme-kit-nextjs';
import { useIntl } from 'react-intl';

import Dropdown from '@/components/Dropdown';
import { useCategories } from '@/hooks';
import { useDevice } from '@/hooks/useDevice';

import CategoryItem from '../CategoriesDropdown/CategoryItem';

import CategoryLink from './CategoryLink';

import styles from './CategoriesBar.module.scss';

function CategoriesBar() {
    const categories = useCategories();
    const currentLocale = useCurrentLocale();
    const { isTablet } = useDevice();
    const { formatMessage } = useIntl();

    const maxDisplayedCategories = isTablet ? 6 : 10;

    const filteredCategories = categories.filter(
        (category) =>
            category.public_stories_number > 0 &&
            getCategoryHasTranslation(category, currentLocale),
    );

    if (!filteredCategories.length) {
        return null;
    }

    const hasMore = filteredCategories.length > maxDisplayedCategories;

    return (
        <div className={styles.container}>
            {filteredCategories.slice(0, maxDisplayedCategories).map((category) => (
                <CategoryLink key={category.id} category={category} />
            ))}
            {hasMore && (
                <Dropdown
                    label={formatMessage(translations.actions.more)}
                    buttonClassName={styles.more}
                    menuClassName={styles.dropdown}
                >
                    {filteredCategories.slice(maxDisplayedCategories).map((category) => (
                        <CategoryItem category={category} key={category.id} />
                    ))}
                </Dropdown>
            )}
        </div>
    );
}

export default CategoriesBar;
